<template>
  <div class="container_margin">
    <b-modal id="modal-center-add-role" centered hide-footer hide-header>
      <div class="card-body">
        <strong class="card-title">{{ "Add" }} {{ "New" }} {{ "Role" }}</strong>

        <div class="card-text mt-3">
          <label for="fname">{{ "Role" }} {{ "Name" }}</label>
        </div>

        <div class="card-text mt-2">
          <b-form-input
            autocorrect="off"
            autocomplete="off"
            v-model="name"
            type="text"
            id="name"
            name="name"
          />
        </div>

        <div class="card-text mt-3">
          <label for="fname">{{ "Description" }}</label>
        </div>

        <div class="card-text mt-2">
          <b-form-textarea
            id="description"
            v-model="description"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </div>

        <div class="card-text d-flex mt-3">
          <button
            class="btn btn-primary-outlined ms-auto"
            @click="$bvModal.hide('modal-center-add-role')"
          >
            {{ "Cancel" }}
          </button>
          <button @click="addRole" class="btn btn-primary ms-2">
            {{ "Add" }}
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal id="modal-center-delete-role" centered hide-footer hide-header>
      <div class="card-body">
        <h6 class="card-title">{{ "Delete" }}</h6>

        <div class="card-text">
          <span>{{ "Do you want to delete Role of id" }} {{ deleteId }} ?</span>
        </div>

        <div class="card-text d-flex mt-3">
          <button
            @click="$bvModal.hide('modal-center-delete-role')"
            class="btn btn-primary-outlined ms-auto"
          >
            {{ "Cancel" }}
          </button>
          <button @click="deleteValue" class="btn btn-red ms-2 text-white">
            {{ "Yes" }}
          </button>
        </div>
      </div>
    </b-modal>

    <div class="row">
      <div class="col">
        <div class="card border shadow-sm p-3 mt-3 rounded">
          <div class="row">
            <div class="col">
              <h5 class="font-weight-bold align-self-center">Roles</h5>
            </div>
            <div class="col-12 col-md-3 col-lg-4 col-xl-2">
              <button
                v-b-modal.modal-center-add-role
                class="float-right btn-primary btn w-100 align-self-center"
              >
                Add Roles
              </button>
            </div>
          </div>

          <div class="row mt-4  table-card">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" width="30%">{{ "Name" }}</th>
                    <th scope="col" width="50%">{{ "Description" }}</th>

                    <th scope="col" width="10%">{{ "Action" }}</th>

                    <th scope="col" width="10%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in roles_list"
                    :key="'ner_list' + index"
                  >
                    <!--   <td class="py-4">
                    <p class="font-weight-bold text-center text-muted"> Start Your Case Search 

                    </p>
                </td> -->
                    <td width="30%">
                      {{ item.name }}
                    </td>
                    <td width="60%">
                      {{ item.description }}
                    </td>

                    <td width="10%">
                      <button @click="editRole" class="btn btn-primary w-100">Edit</button>
                    </td>
                    <td width="10%">
                      <button
                        @click="deleteRole"
                        class="btn btn-primary-outlined w-100"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      roles_list: [
        {
          name: "Role",
          description: "Role Description",
        },
        // Add more employee objects as needed
      ],
    };
  },
  methods: {
    addRole() {
      // Implement your logic to add an employee

      this.$bvModal.hide("modal-center-add-role");
    },
    editRole(index) {
      // Implement your logic to edit an employee
      this.$router.push({
        name: "role",
      });
    },
    deleteRole(data) {
      this.$bvModal.show("modal-center-delete-role");
    },

    deleteValue() {
      this.$bvModal.hide("modal-center-delete-role");
    },
  },
};
</script>






<style scoped>
.container_margin {
  padding: 2rem;
}
</style>